import { Component } from '@angular/core';
import { first, tap } from 'rxjs';
import { indicate } from 'src/app/operators';
import {
  DictionaryItem,
  DictionaryItemDefinition,
  DictionarySimplePageComponent 
} from 'src/app/shared/components/dictionary-simple-page/dictionary-simple-page.component';
import { BranchTableCodeViewModel, EsraPipingAPIClient } from 'src/app/shared/models/autogenerated-piping';
import { DictionaryDeleteComponent } from '../dialogs/dictionary-delete/dictionary-delete.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { InsightsService } from 'src/app/shared/services/insights.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-branch-table-codes',
  templateUrl: '../../../../shared/components/dictionary-simple-page/dictionary-simple-page.component.html',
  styleUrls: ['../../../../shared/components/dictionary-simple-page/dictionary-simple-page.component.scss']
})
export class BranchTableCodesComponent extends DictionarySimplePageComponent<BranchTableCodeViewModel> {
  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private insightsService: InsightsService,
    private dialog: MatDialog
  ) { super(); }

  protected searchAll(): void {
    this.esraApiClient.getAllCurrentBranchTableCodes()
      .pipe(
        indicate(this.searching$),
        first(),
        tap(branchTableCodes => {
          this.dataSource.data = branchTableCodes;
          this.totalCount = branchTableCodes.length;
        }))
        .subscribe();
  }

  protected initializeDictionaryDefinition(): DictionaryItemDefinition<BranchTableCodeViewModel>{
    return {
      displayStatusColumn: true,
      dictionaryItems: [
          new DictionaryItem('Code','code'),
          new DictionaryItem('Description', 'description', 'extended')
        ]
    };
  }

  protected getFilterPredicate(record: BranchTableCodeViewModel, parametersMap: Map<string, string>): boolean {
    const searchQuery = parametersMap.get(DictionarySimplePageComponent.SEARCH_QUERY_NAME)?.toLowerCase() || '';
    const isActiveFilter = parametersMap.get(DictionarySimplePageComponent.IS_ACTIVE_NAME);

    const matchesSearchQuery = (record.code.toLowerCase().includes(searchQuery) ||
        record.description.toLowerCase().includes(searchQuery) );

    if (isActiveFilter == null) {
        return matchesSearchQuery;
    } else {
        return matchesSearchQuery && record.isActive === JSON.parse(isActiveFilter);
    }
  }

  protected createNew(): void {
    window.open('piping/branchTableCodeAddEdit');
  }

  protected editItem(branchTableCode: BranchTableCodeViewModel): void {
    localStorage.setItem('branchTableCode', JSON.stringify(branchTableCode));
    window.open('piping/branchTableCodeAddEdit');
  }

  protected editItemReferenced(): void {
    throw new Error('Method not implemented.');
  }

  protected deleteItem(item: BranchTableCodeViewModel): void {
    this.dialog.open(DictionaryDeleteComponent, {
      width: '40%',
      height: '23%',
      data: {item: item,
        objectName: item.code,
        objectType: 'branch table code'
      }
    }).afterClosed().subscribe((result) => {
      if(result) {
        this.esraApiClient.deleteBranchTableCode(item.id)
        .pipe(first(),
          tap(item => {
              this.snackBarService.showInfo("Branch Table Code: " + item.code + " was deleted");
              this.searchAll();
            }))
        .subscribe({
          error: (err) => {
            this.snackBarService.showInfo("Unable to delete Branch Table Code: " + item.code + "");
            this.insightsService.logException(err);
          },
        });
      }
    })
  }

  protected updateStatus(item: BranchTableCodeViewModel) {
    this.esraApiClient.toggleIsActiveBranchTableCode(item.isActive, [item.id]).pipe(indicate(this.searching$), first(),
    tap(() => this.search())).subscribe();
  }

  protected getUsageInformation(): void {
    throw new Error('Method not implemented.');
  }

  protected getNoMatchMessage(): string {
    return "No branch table codes match provided search criteria";    
  }
}