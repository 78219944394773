import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, first, tap } from "rxjs";
import { ComponentPropertyViewModel, EsraPipingAPIClient, UsedIn, ValveViewModel } from "src/app/shared/models/autogenerated-piping";
import { indicate } from "src/app/operators";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DownloadService } from "src/app/core/services/download.service";

@Component({
    selector: 'app-valve-summary-page',
    templateUrl: './valve-summary-page.component.html',
    styleUrls: ['./valve-summary-page.component.scss']
})
export class ValveSummaryPageComponent implements OnInit {

    loadingIndicator$ = new BehaviorSubject<boolean>(false);
    valveViewModel$: Observable<ValveViewModel>;
    openState = true;
    faDownload = faDownload;
    pipeClasses: { name: string, id: string }[] = []
    valveVM;
    componentPropertiesToDisplay: ComponentPropertyViewModel[] = []

    constructor(
        private route: ActivatedRoute,
        private esraPipingAPIClient: EsraPipingAPIClient,
        private downloadService: DownloadService
    ) {}

    ngOnInit() {
        const valveId: string = this.route.snapshot.paramMap?.get('id');
        if (valveId) {
            this.valveViewModel$ = this.esraPipingAPIClient
                .getValveViewModelById(valveId)
                .pipe(
                    indicate(this.loadingIndicator$),
                    first(),
                    tap((valve) => {
                        this.prepareValveData(valve);
                    })
                );
        }
    }

    private prepareValveData(valve: ValveViewModel) {
        valve.pipeClassNames
            .forEach( (name, index) => 
                this.pipeClasses.push({ name: name, id: valve.pipeClassIds[index] }));

        this.componentPropertiesToDisplay = valve.componentProperties;

        this.componentPropertiesToDisplay.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    replaceUnderscoreWithSpace(value: string) {
        return value.replace("_", " ");
    }

    exportToPdf(guid: string) {
        return this.downloadService.downloadFile(
          this.esraPipingAPIClient
            .exportValveToPdf(guid)
            .pipe(indicate(this.loadingIndicator$))
        );
      }
    
    exportToExcel(guid: string) {
        return this.downloadService.downloadFile(
            this.esraPipingAPIClient
            .exportValveToExcel(guid)
            .pipe(indicate(this.loadingIndicator$))
        );
    }

    public sortUsedIns(usedIns: UsedIn[]){
        return usedIns.sort((a, b) => a.businessUnit.localeCompare(b.businessUnit));
    }
}