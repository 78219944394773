import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NextpageModule } from './nextpage/nextpage.module';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './msal.setup';
import { SearchModule } from './search/search.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { SampleModule } from './sample/sample.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MyWorkspaceModule } from './my-workspace/my-workspace.module';
import { MatTabsModule } from '@angular/material/tabs';
import { HelpModule } from './help/help.module';
import { RevisionsModule } from './revisions/revisions.module';
import { AdmintoolModule } from './admintool/admintool.module';
import { PipingValvesModule } from './piping-valves/piping-valves.module';
import { ConnectionErrorComponent } from './shared/components/connection-error/connection-error.component';
import { EsraPipingAPIClient } from './shared/models/autogenerated-piping';
import { HttpErrorInterceptor } from './core/handlers/HttpErrorInterceptor';

const appComponents = [AppComponent, ConnectionErrorComponent];

@NgModule({ declarations: [appComponents],
    exports: [appComponents, NextpageModule],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FontAwesomeModule,
        HelpModule,
        MatIconModule,
        MatProgressBarModule,
        MatTableModule,
        MatSortModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatTabsModule,
        MsalModule,
        MyWorkspaceModule,
        NextpageModule,
        RevisionsModule,
        SampleModule,
        SearchModule,
        SharedModule,
        HelpModule,
        RevisionsModule,
        AdmintoolModule,
        PipingValvesModule], providers: [
        {
            provide: EsraPipingAPIClient, useClass: EsraPipingAPIClient
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

export class AppTest {
  constructor(private injector: Injector) {}
  ngBootStrap() {
    // This is intentionally empty
  }
}
