import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { EsraPipingAPIClient, PressureTemperatureTableViewModel } from 'src/app/shared/models/autogenerated-piping';
import { faSearch, faTrash, faPen, faPlusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { PressureTempPreviewComponent } from '../dialogs/pressure-temp-preview/pressure-temp-preview.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ServiceNoteUsageComponent } from '../dialogs/service-note-usage/service-note-usage.component';
import { DictionaryDeleteComponent } from '../dialogs/dictionary-delete/dictionary-delete.component';
import { productionGuard } from 'src/app/core/guards/guards';
import { InsightsService } from 'src/app/shared/services/insights.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-pressure-temperature',
  templateUrl: './pressure-temperature.component.html',
  styleUrls: ['./pressure-temperature.component.scss']
})
export class PressuretemperatureComponent implements OnInit {
  dataSource = new MatTableDataSource();
  sortColumn: string = '';
  sortDirection: string = '';

  searchText = '';
  columnNames: string[] = ['NAME', 'PREVIEW', 'STATUS'];

  faEdit = faPen;
  faTrashCan = faTrash;
  faSearch = faSearch;
  faPlusCircle = faPlusCircle;
  faInfo = faInfoCircle;

  // paginator variables
  currentPage = 0;
  pageSize = 25;
  totalCount: number;
  pageSizes = [2, 5, 10, 25, 50, 100];

  searching$ = new BehaviorSubject<boolean>(false);

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private insightsService: InsightsService
  ) {}

  ngOnInit(): void {
    this.sortDirection = 'asc';
    this.updateSearchResults(this.sortDirection, this.searchText);
    if (productionGuard()) {
      this.columnNames.push('ACTIONS');
    }
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updateSearchResults(this.sortDirection, this.searchText);
  }

  callSearch(e) {
    if (e.keyCode == 13) {
      this.currentPage = 0;
      this.paginator.firstPage();
      this.search();
    }
  }

  public clearSearchText() {
    this.currentPage = 0;
    this.paginator.firstPage();
    this.searchText = '';
    this.search();
  }

  public search() {
    this.updateSearchResults(
      this.sortDirection,
      this.searchText
    );
    this.updateSearchResults(
      this.sortDirection,
      this.searchText
    );
  }

  private updateSearchResults(sortDirection: string, searchPhrase: string) {
    this.esraApiClient.getPaginatedPressureTemperatureTables(
      Number(this.currentPage),
      Number(this.pageSize),
      sortDirection,
      searchPhrase
    )
    .pipe(
      indicate(this.searching$),
      first(),
      tap(searchResult => {
        this.totalCount = searchResult.totalResults;
        this.dataSource.data = searchResult.results;
        this.dataSource.sort = this.sort;
      })
    ).subscribe();
  }

  public openDeletionDialog(pressureTemperatureTable: PressureTemperatureTableViewModel) {
    this.dialog.open(DictionaryDeleteComponent, {
      width: '40%',
      height: '23%',
      data: {
        item: pressureTemperatureTable,
        objectName: 'pressure temperature table',
        objectType: 'pressure temperature table'
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.esraApiClient
          .deletePressureTemperatureTableById(pressureTemperatureTable.id)
          .subscribe({
            next: () => {
              this.snackBarService.showSnackBar(
                true,
                `Pressure temperature table "${pressureTemperatureTable.name}" was successfully deleted.`,
                'mat-snack-bar-info'
              );
              this.search();
            },
            error: (error) => {
              this.snackBarService.showSnackBar(
                true,
                `Sorry, something went wrong. Error trying to delete pressure temperature table "${pressureTemperatureTable.name}".`,
                'mat-snack-bar-error'
              );
              this.insightsService.logException(error);
            }
          });
      }
    });
  }

  public editComponent(pressureTemperatureTableVM: PressureTemperatureTableViewModel) {
    localStorage.setItem('pressureTemperatureTable', JSON.stringify(pressureTemperatureTableVM));
    window.open('path/pressureTemperatureAddEdit');
  }

  public createNewTable() {
    window.open('path/pressureTemperatureAddEdit');
  }

  public previewTable(tableVM: PressureTemperatureTableViewModel[]) {
    this.dialog.open(PressureTempPreviewComponent, {
        minWidth: '60vw',
        minHeight: '50vh',
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: tableVM
      });
  }

  public getUsageInformation(table: PressureTemperatureTableViewModel) {
    this.dialog.open(ServiceNoteUsageComponent, {
      width: '40%',
      height: '40%',
      data: table
    })
  }
  
  protected updateStatus(item: PressureTemperatureTableViewModel) {
    this.esraApiClient.toggleIsActivePTTable(item.isActive, [item.id]).pipe(indicate(this.searching$), first(),
    tap(() => this.search())).subscribe({error: () => this.search()});
  }

  protected sortData(sort: Sort) {
    this.sortDirection = sort.direction;
    this.updateSearchResults(this.sortDirection, this.searchText);
  }
}
