import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of
} from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, tap } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  FilterableProperty,
  SearchRequest,
  WeldingProcedureViewModel,
  EsraWeldingAPIClient,
  WeldingSearchFilterViewModel,
  EsraSearchFilterModel
} from 'src/app/shared/models/autogenerated-welding';
import { TechnologyEnum } from 'src/app/shared/models/technology-enum';
import { FilterDataService } from '../../services/filter.service';
import { RangeFilterHelper } from '../common/range-filter.helper';
import { FilterablePropertyFactory } from 'src/app/shared/components/factory/filterable-property-factory.service';

@Component({
  selector: 'app-welding-search',
  templateUrl: './welding-search.component.html',
  styleUrls: ['./welding-search.component.scss'],
})
export class WeldingSearchComponent implements OnInit, OnDestroy {
  faSearch = faSearch;
  searchText = '';
  currentPage = 0;
  technology: TechnologyEnum = TechnologyEnum.WELDING;
  lastPage: number;

  totalCount: number;
  pageSize = 10;

  filterableProperties: FilterableProperty[];
  private readonly subscription = new Subscription();
  _all = 'common';

  weldingProducts$: Observable<WeldingProcedureViewModel[]>;

  searching$ = new BehaviorSubject<boolean>(false);
  loadingFilters$ = new BehaviorSubject<boolean>(false);
  exportingPdf$ = new BehaviorSubject<boolean>(false);

  filtersForWelding$: Observable<
    Array<WeldingSearchFilterViewModel>
    > = this.getWeldingFiltersList();

  facetValues = new BehaviorSubject<Array<EsraSearchFilterModel>>(
    new Array<EsraSearchFilterModel>()
  );
  facetValues$ = this.facetValues.asObservable();

  latestFilterSelection$ = new BehaviorSubject<string>('');

  weldingFilters$ = this.getWeldingFilters();

  pageSizes = [2, 5, 10, 25, 50, 100];
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private filterDataService: FilterDataService,
    public rangeFilterHelper: RangeFilterHelper
  ) { }

  ngOnInit() {
    this.resetFilters();

    this.subscription.add(
      this.filterDataService.currentFiltersSelection$
        .pipe(
          map((filterSelection) => {
            this.filterableProperties = Array.from(filterSelection.values());
            this.currentPage = 0;
            this.search();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  resetFilters() {
    this.filterDataService.removeAllFilters();
  }

  updateAdditionalRadioFilterSelection(
    event: string,
    filterPropertyName: string,
    filterDiplayName: string
  ) {
    if (event === '') {
      this.filterDataService.removeAllFilterValues(filterPropertyName);
    } else {
      const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
        0,
        filterPropertyName,
        filterDiplayName
      );
      filterableProperty.filterOptions = [event];
      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  updateAdditionalTextFilterSelection(
    event: string,
    filterPropertyName: string,
    filterDiplayName: string
  ) {
    const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
      5,
      filterPropertyName,
      filterDiplayName
    );
    filterableProperty.filterOptions = [event];
    this.filterDataService.setFilterValue(filterableProperty);
  }

  updateAdditionalCheckboxFilterSelection(
    event: Set<string>,
    filterablePropertyName: string,
    filterDiplayName: string
  ) {
    this.latestFilterSelection$.next(filterablePropertyName);
    if (event.size === 0) {
      this.filterDataService.removeAllFilterValues(filterablePropertyName);
    } else {
      const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
        1,
        filterablePropertyName,
        filterDiplayName
      );

      filterableProperty.filterOptions = Array.from(event);
      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  updateSingleSliderFilterSelection(
    event: string,
    filterablePropertyName: string,
    filterDiplayName: string,
    filterType: string
  ) {
    this.latestFilterSelection$.next(filterablePropertyName);
    let filterTypeNum = 2; // default value for minimum

    if (filterType === 'MAXIMUM') {
      filterTypeNum = 3;
    }

    const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
      filterTypeNum,
      filterablePropertyName,
      filterDiplayName
    );
    filterableProperty.filterOptions = [event];
    this.filterDataService.setFilterValue(filterableProperty);
  }

  updateRangeFilterSelection(
    event: string[],
    filterablePropertyName: string,
    filterDiplayName: string
  ) {
    this.latestFilterSelection$.next(filterablePropertyName);
    const rangeFilterProp = FilterablePropertyFactory.createFilterbleProperty(
      4,
      filterablePropertyName,
      filterDiplayName
    );
    rangeFilterProp.filterOptions = [event[0] + ':' + event[1]];
    this.filterDataService.setFilterValue(rangeFilterProp);
  }

  private search(): void {
    const request: SearchRequest = new SearchRequest();
    request.perPage = this.pageSize;
    request.page = this.currentPage;
    request.mainSearch = this.searchText;
    request.filters = this.filterableProperties;
    this.subscription.add(
      this.esraWeldingApiClient
        .searchWeldingProcedures(request)
        .pipe(
          indicate(this.searching$),
          tap((response) => {
            this.weldingProducts$ = of(response.results);
            this.facetValues.next(response.searchFilters);
            this.totalCount = response.totalResults;
            this.lastPage = Math.ceil(this.totalCount / this.pageSize);
          })
        )
        .subscribe()
    );
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.search();
  }

  private getWeldingFilters(): Observable<
    Array<WeldingSearchFilterViewModel>
  > {
    return combineLatest([
      this.facetValues$,
      this.filtersForWelding$,
      this.latestFilterSelection$,
    ]).pipe(
      map((values) => {
        const facets = values[0];
        const filters = values[1];

        filters.forEach((f) => {
          if (f.filterPropertyName != values[2]) {
            let matchingFacets: any;

            if (f.filterPropertyName == "WeldProcess") {
              matchingFacets = facets.filter(
                (x) => x.filterPropertyName === "WeldProcesses/Value"
              );
            }
            else if (f.filterPropertyName == "Electrodes") {
              matchingFacets = facets.filter(
                (x) => x.filterPropertyName === "Electrodes/Value"
              );
            }
            else {
              matchingFacets = facets.filter(
                (x) => x.filterPropertyName === f.filterPropertyName
              );
            }
            if (matchingFacets[0] != null) {
              f.options = matchingFacets[0].options;
              f.minValue = matchingFacets[0].minValue;
              f.maxValue = matchingFacets[0].maxValue;

              f.options.sort((a, b) =>
                a.localeCompare(b, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              );
            }
          }
        });
        return filters;
      })
    );
  }
  private getWeldingFiltersList(): Observable<
    Array<WeldingSearchFilterViewModel>
  > {
    return this.esraWeldingApiClient
          .getWeldingFilters()
          .pipe(indicate(this.loadingFilters$))
  }

  popClearedFilter($event: any, filterPropertyName: string) {
    if (this.latestFilterSelection$.getValue() === filterPropertyName) {
      this.latestFilterSelection$.next('');
    }
  }

  callSearch(e: any) {
    if (e.keyCode == 13) this.search();
  }

  public exportAllToPdf() {
    const request: SearchRequest = new SearchRequest();
    request.perPage = this.pageSize;
    request.page = this.currentPage;
    request.mainSearch = this.searchText;
    request.filters = this.filterableProperties;
  }

  notTrueFalseOptionOnly(filter: WeldingSearchFilterViewModel): boolean {
    const optionsLowerCased = filter.options.map((filterOption) =>
      filterOption.toLowerCase()
    );
    return !(
      optionsLowerCased.length == 2 &&
      optionsLowerCased.includes('true') &&
      optionsLowerCased.includes('false')
    );
  }

  public clearSearchText() {
    this.searchText = '';
    this.search();
  }
}
