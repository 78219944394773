import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { FilterDataService } from '../../services/filter.service';
import { FilterableProperty } from 'src/app/shared/models/autogenerated-welding';

@Component({
  selector: 'app-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
})
export class TextFilterComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  @Input()
  title: string;
  @Input()
  numeric: boolean;
  @Input()
  options: Array<string>;
  @Input()
  inputPlaceholder: string;
  @Input()
  selectedOptionString: string;
  @Input()
  propertyName: string;
  @Input()
  step: number;
  @Input()
  defaultStepValue: number;


  @Output()
  public thickness = new EventEmitter<string>();
  @Output()
  public selectedOption = new EventEmitter<string>();

  inputText: string;
  warning: string;

  _defaultPlaceholder = 'thickness in inches';
  _defaultSelectedOptionString = '=';

  constructor(filterDataService: FilterDataService) {
    this.subscription.add(filterDataService.getResetSubject().pipe(tap(resetRequest => {
      if(resetRequest){
        this.clearInputs();
      }
    })).subscribe());
    this.subscription.add(filterDataService.currentFiltersSelection$.pipe(tap(filters => this.resetFilter(filters) )).subscribe());
  }

  private resetFilter(filters: Map<string, FilterableProperty>) {
    if(!filters.has(this.propertyName)) {
      this.clearInputs
    }
  }

  ngOnInit() {
    if (this.inputPlaceholder == null) {
      this.inputPlaceholder = this._defaultPlaceholder;
    }
    if (this.selectedOptionString == null) {
      this.selectedOptionString = this._defaultSelectedOptionString;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  applyFilter() {
    let strInputText = '';
    if (this.inputText !== null) {
      strInputText = this.inputText.toString();
    }

    this.selectedOption.emit(this.selectedOptionString);
    if (strInputText.trim().startsWith('.')) {
      strInputText = '0' + strInputText;
    }
    
    const numeric = Number(strInputText);

    if (isNaN(numeric)) {
      this.warning = 'value must be a number';
    } else {
      this.warning = null;
      this.thickness.emit(strInputText);
    }
  }
  
  onSelection(eventValue: string) {
    this.selectedOptionString = eventValue;
  }

  clearInputs() {
    this.inputText = '';
    this.selectedOptionString = null;
  }
}