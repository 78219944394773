import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { PipeClassViewModel } from '../../models/autogenerated-piping';
import { ProductPanelHelper } from '../product-panel/product-panel-helper';
import { TechnologyEnum } from 'src/app/shared/models/technology-enum';
import { PipingAndValvesNavigationService } from 'src/app/piping-valves/services/piping-and-valves-navigation.service';
import { PipingAndValvesDocumentService } from 'src/app/piping-valves/services/piping-and-valves-document.service';

@Component({
  selector: 'app-pipeclass-panel',
  templateUrl: './pipeclass-panel.component.html',
  styleUrls: ['./pipeclass-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PipeClassPanelComponent implements OnInit {
  @Input()
  pipeClass: PipeClassViewModel;
  @Input()
  addWorkspaceShow: boolean;
  @Input()
  isAdminSearch: boolean;

  @Output()
  technology: TechnologyEnum = TechnologyEnum.PIPES;
  @Output()
  outputPipeClass: PipeClassViewModel;
  @Output()
  openPage = new EventEmitter<string>();

  constructor(
    private productPanelHelper: ProductPanelHelper,
    private pipingAndValvesNavigationService: PipingAndValvesNavigationService,
    private pipingAndValvesDocumentService: PipingAndValvesDocumentService
  ) {}

  ngOnInit(): void {
    this.pipeClass.name = 
      this.productPanelHelper.strReplaceHighlightHtml(this.pipeClass.name);

    this.pipeClass.businessUnits = 
      this.productPanelHelper.arrReplaceHighlightHtml(
        this.pipeClass.businessUnits
      );

    this.pipeClass.application = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.application
      );

    this.pipeClass.service.name = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.service.name
      );

    this.pipeClass.nominalCorrosionAllowance = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.nominalCorrosionAllowance
      );

    this.pipeClass.minimumTemperature = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.minimumTemperature
      );

    this.pipeClass.maximumTemperature = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.maximumTemperature
      );

    this.pipeClass.material.value = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.material.value
      );

    this.pipeClass.valveTrim.value = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.valveTrim.value
      );

    this.pipeClass.relatedVersions = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.relatedVersions
      );

    this.pipeClass.sizeRange = this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.sizeRange
      );

    this.pipeClass.ratingClass.value = 
      this.productPanelHelper.strReplaceHighlightHtml(
        this.pipeClass.ratingClass.value
      );
  }

  public stripEmphasisClassFromString(str: string): string {
    return ProductPanelHelper.RemoveHTML(str);
  }

  tryOpenPipeSummaryPage() {
    if (this.isAdminSearch) {
      this.pipingAndValvesNavigationService.openPipingSummaryPage(
        this.pipeClass.id
      );
    } else {
      this.pipingAndValvesDocumentService.openPDF(
        this.pipeClass.name,
        this.pipeClass.businessUnits[0]
      );
    }
  }
}
