import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { Observable, Subscription, tap } from 'rxjs';
import { FilterDataService } from 'src/app/search/services/filter.service';
import { Constants } from 'src/app/shared/constants/constants';
import { FilterableProperty } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss'],
})
export class MultiSelectFilterComponent implements OnChanges, OnDestroy {
  private readonly subscription = new Subscription();
  public checkList = new Array<CheckButton>();

  public customCollapsedHeight = '40px';
  public panelOpenState = false;

  @Input()
  title: string;
  @Input()
  propertyName: string;
  @Input()
  multiselect: boolean;
  @Input()
  options: string[];
  @Input()
  filteringEnabled = false;

  @Output()
  public selectedRadioOption = new EventEmitter<string>();
  @Output()
  public selectedCheckboxOptions = new EventEmitter<Set<string>>();
  @Output()
  public cleared = new EventEmitter<unknown>();
  @Output()
  lastDeselectedOption = new EventEmitter<string>();

  checkedOptions = new Set<string>();

  constructor(filterDataService: FilterDataService) {
    this.subscription.add(filterDataService.getResetSubject().pipe(tap(resetRequest => {
      if(resetRequest){
        this.clearSelections();
      }
    })).subscribe()); 
    this.subscription.add(filterDataService.currentFiltersSelection$.pipe(tap(filters => this.selectUnselect(filters) )).subscribe());
  }

  private selectUnselect(filters: Map<string, FilterableProperty>): void {
    if(filters.has(this.propertyName)) {
      const filterableProperty = filters.get(this.propertyName)
      this.checkList.forEach(checkBox => {
        if(!filterableProperty.filterOptions.includes(checkBox.value)){
          checkBox.isSelected = false;
          this.checkedOptions.delete(checkBox.value)
        } else {
          checkBox.isSelected = true;
          this.checkedOptions.add(checkBox.value)
        }
      });
    } else if(this.checkedOptions.size > 0) {
      this.checkList.forEach(checkBox => checkBox.isSelected = false);
      this.checkedOptions = new Set<string>();
    }
  }

  ngOnChanges(): void {
    this.reconstructCheckButtons();
  }

  reconstructCheckButtons(): void {
    const newCheckList = new Array<CheckButton>();
    let id = 0;
    this.options.forEach((item) => {
      const cb = new CheckButton();
      cb.id = id++;
      if (item && item.split(Constants.esraDelimiter).length > 1) {
        cb.value = item.split(Constants.esraDelimiter)[0];
        cb.label = '- ' + item.split(Constants.esraDelimiter)[1];
      } else {
        cb.value = item;
      }
      if (this.checkedOptions.has(cb.value)) {
        cb.isSelected = true;
      }
      newCheckList.push(cb);
    });

    this.checkList = newCheckList;
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onRadioChange(event: MatRadioChange) {
    this.checkList.forEach((checkbox) => {
      if (checkbox.value === this.options[event.value]) {
        checkbox.isSelected = true;
      } else {
        checkbox.isSelected = false;
      }
    });

    this.selectedRadioOption.emit(this.options[event.value]);
  }

  onCheckboxChange(event: MatCheckboxChange, value: string) {
    if (event.checked) {
      this.checkedOptions.add(value);
    } else {
      this.checkedOptions.delete(value);
      this.lastDeselectedOption.emit(value);
    }

    this.checkList
      .filter((checkbox) => checkbox.value === value)
      .forEach((filterItem) => {
        filterItem.isSelected = event.checked;
      });

    this.selectedCheckboxOptions.emit(this.checkedOptions);
  }

  clearSelections() {
    this.checkedOptions.clear();
    if (this.multiselect) {
      this.checkList.forEach((filterItem) => {
        filterItem.isSelected = false;
      });
      this.ngOnChanges();

      this.selectedCheckboxOptions.emit(new Set<string>());
    } else {
      this.checkList.forEach((checkbox) => {
        checkbox.isSelected = false;
      });

      this.selectedRadioOption.emit('');
    }
    this.cleared.emit();
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue?.trim()) {
      this.checkList.forEach((checkbox) => {
        if (
          checkbox.value
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase())
        ) {
          checkbox.isVisibleInFilter = true;
        } else {
          checkbox.isVisibleInFilter = false;
        }
      });
    } else {
      this.checkList.forEach((checkbox) => (checkbox.isVisibleInFilter = true));
    }
  }
  
  public addAllSelections() {
    const completeOptionSet = new Set<string>();
    this.checkList.forEach((option) => {
      if(option.isVisibleInFilter){
        completeOptionSet.add(option.value);
        this.checkedOptions.add(option.value);
        option.isSelected = true;
      }      
    })
    this.selectedCheckboxOptions.emit(completeOptionSet);
  }
}

class CheckButton {
  public id = 0;
  public value = '';
  public label = '';
  public isSelected = false;
  public isVisibleInFilter = true;
}